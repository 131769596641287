import React, { useEffect } from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
//import { StaticImage } from "gatsby-plugin-image";

const MembersThanks = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Members Thank You"
				description="Thank you for signing up for our Silver Riders Club Membership, we will be in touch soon!"
			/>
			{/* <div className="">
				<StaticImage
					className="rounded-lg shadow-md transform"
					src="../../images/sikkim-bike-tour-22.jpeg"
					alt="cycling adventures"
					layout="FULL_WIDTH"
					aspectRatio="2.12"
				/>
			</div> */}
			<section className="my-20">
				<h1 className="font-bold">Welcome to Silver Riders Club</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					Many thanks for joining. We will be in touch soon!
				</h2>
				<p className="max-w-2xl">
					If you are in the process of booking a trip with us and wish to take
					advantage of the Membership discount, please proceed with your booking. We
					will apply the discount from our backend as soon as we receive your
					booking.
				</p>
				<p className="max-w-2xl mt-4">
					We invite you to follow our{" "}
					<a
						href="https://www.instagram.com/artofbicycleglobal/"
						target="blank"
						className="underline font-semibold"
					>
						Instagram Channel
					</a>{" "}
					for the latest updates and stay in the loop.
				</p>
				<button className="mt-6 mb-4">
					<a
						href="https://www.instagram.com/artofbicycleglobal/"
						target="blank"
						className="bg-articlecta text-white font-medium py-2 px-4 rounded-lg"
					>
						Follow us
					</a>
				</button>
			</section>
		</Layout>
	);
};

export default MembersThanks;
